import React from "react";
import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemState,
  AccordionItemPanel,
} from "react-accessible-accordion";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import Testimonials from "../components/Repeating/Testimonials";
import PracticeAreasSidebar from "../components/Repeating/PracticeAreasSidebar";
import Attorneys from "../components/Repeating/Attorneys";
import WhyUs from "../components/Repeating/WhyUs";
import CallToAction from "../components/Repeating/CTA";
import ButtonSolid from "../components/Button/ButtonSolid";

const Page = ({ data }) => {
  const accordionContent = [
    {
      title: "Trustee Accounting",
      content: (
        <>
          <p>
            Trustees are legally required to perform fiduciary duties and can
            face serious ramifications if they fail to provide accountings to
            the beneficiaries. They can even be held personally liable if they
            fail to fulfill their fiduciary duties.
          </p>
        </>
      ),
    },
    {
      title: "Conservatorship Accounting",
      content: (
        <>
          <p>
            In a conservatorship, a court appoints a person (or persons) to
            manage the affairs of another adult who’s been legally determined to
            be unable to care for themselves. They have a fiduciary
            responsibility and are required to keep strict financial records
            when managing the estate.
          </p>
        </>
      ),
    },
    {
      title: "Guardianship Accounting",
      content: (
        <>
          <p>
            When a child’s parents are physically or mentally unable to make
            decisions for their child, the court can appoint a{" "}
            <a href="https://briertonjones.com/guardianship-lawyer/">
              guardian
            </a>{" "}
            who can legally step in. Guardians must keep documentation that
            shows they are using reasonable care and caution in handling the
            money.
          </p>
        </>
      ),
    },
  ];

  return (
    <Layout>
      <SearchEngineOptimization
        title="Trust Accountings San Diego | Brierton, Jones & Jones"
        description="When you need help with your trust accountings in San Diego, Brierton, Jones & Jones, LLP is here to deliver. Learn more and schedule a consultation today!"
        openGraphImage={data.openGraphImage.publicURL}
        twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />
      <section className="bg-gray-50 pb-6 pt-12 md:pb-16 md:pt-10">
        <div className="relative">
          <div className="container">
            <div className="grid items-center gap-y-14 md:min-h-[506px] md:grid-cols-2">
              <div className="md:pr-6 lg:pr-12">
                <div className="mb-6 text-sm font-bold uppercase tracking-wide text-gray-300">
                  MEET THE REQUIREMENTS
                </div>
                <h1>San Diego Trust Accountings</h1>
                <p>
                  Periodic accountings are often required for estates,
                  guardianships, conservatorships, and trusts, whether or not
                  they are court-supervised. At Brierton, Jones & Jones, LLP, we
                  counsel clients on both sides—those who prepare the
                  accountings and those who receive them.
                </p>
                <ButtonSolid
                  modal="modal-contact"
                  text="Schedule a Consultation"
                />
              </div>

              <div className="-mr-4 md:absolute md:right-0 md:top-0 md:mr-0 md:h-full md:w-[50vw] md:pl-6 lg:pl-12">
                <StaticImage
                  src="../images/12.0 Accountings/1.0 Hero desktop.jpg"
                  loading="eager"
                  className="h-full rounded-l-[90px]"
                  imgClassName="object-left"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="mb-20 pt-14 md:mb-32 md:pt-20">
        <div className="container">
          <div className="flex justify-between md:space-x-14 lg:space-x-28">
            <div>
              <div className="mb-12 md:mb-18">
                <h2>What Are Accountings, Exactly?</h2>
                <p>
                  Accountings are a detailed record of all of the income and
                  expenses for an estate or trust, or other form of legal
                  binding, such as a{" "}
                  <a href="https://briertonjones.com/conservatorship-lawyer/">
                    conservatorship
                  </a>
                  . Maintaining accurate records is paramount to ensure the
                  funds are being handled correctly, and according to the law.
                </p>
              </div>

              <div className="mb-12 md:mb-18">
                <h2>If You Need Help With Accountings</h2>
                <p>
                  If you’re a trustee, guardian, conservator or agent, we’ll
                  counsel you on:
                </p>
                <ul className="mb-6 list-disc pl-6">
                  <li>Proper recordkeeping</li>
                  <li>Considerations for limiting liability exposure</li>
                  <li>Required disclosures</li>
                  <li>The consequences of failing to properly account</li>
                </ul>
                <p>And we’ll provide you with an analysis of the accountings</p>
              </div>

              <div className="mb-12 md:mb-18">
                <h2>If You’re a Client Entitled to Accountings</h2>
                <p>We’ll counsel you regarding:</p>
                <ul className="mb-6 list-disc pl-6">
                  <li>Your rights to receive accountings</li>
                  <li>
                    The steps to enforce your rights to receive accountings if
                    the fiduciary is not providing it to you
                  </li>
                  <li>
                    The time period you have to object to acts or inactions by
                    the fiduciary
                  </li>
                </ul>
                <p>And we’ll provide you with an analysis of accountancy.</p>
              </div>

              <div>
                <h3>Understanding the Terminology</h3>
                <p>
                  If you’re considering getting help with trust accountings,
                  here are some of the common terms to familiarize yourself
                  with:
                </p>
                <Accordion
                  allowZeroExpanded={true}
                  className="flex flex-col space-y-5 border-t border-gray-100 pt-5"
                >
                  {accordionContent.map((item, i) => (
                    <AccordionItem
                      uuid={i}
                      className="border-b border-gray-100 pb-5"
                    >
                      <AccordionItemButton className="flex items-center justify-between focus:outline-none">
                        <AccordionItemState>
                          {(state) => {
                            return (
                              <>
                                <h3 className="mb-0 font-display text-xl font-medium text-typography-heading no-underline">
                                  {item.title}
                                </h3>
                                <i
                                  className={`far ml-2 text-xl text-seafoam ${
                                    state.expanded
                                      ? "fa-minus"
                                      : "fa-chevron-down"
                                  }`}
                                ></i>
                              </>
                            );
                          }}
                        </AccordionItemState>
                      </AccordionItemButton>

                      <AccordionItemPanel className="pt-5">
                        {item.content}
                      </AccordionItemPanel>
                    </AccordionItem>
                  ))}
                </Accordion>
              </div>
            </div>
            <div className="hidden md:block">
              <PracticeAreasSidebar activeLink={11} />
            </div>
          </div>
        </div>
      </section>

      <Attorneys />
      <WhyUs />
      <Testimonials />
      <CallToAction text="Meet with one of our San Diego trust accountings attorneys if you have questions or want more information." />
    </Layout>
  );
};

export const query = graphql`
  {
    openGraphImage: file(
      relativePath: { eq: "Global/Open Graphs/Facebook/12 Accountings.jpg" }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "Global/Open Graphs/Twitter/12 Accountings.jpg" }
    ) {
      publicURL
    }
  }
`;

export default Page;
